<template>
  <div>
      <h2>Not Found {{this.id}}</h2>
    
  </div>
</template>

<script>
export default {
  data(){
        return{
          id:this.$route.params.id
            
        }
    },
	mounted(){
       
    },
  
    methods:{
        
        
    },
    created(){

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>